<template>
  <div class="hotel">
    <Header class="hide" />
    <Headers class="dispy" />
    <div class="bar">
      <BannerImage rulename="enterprise_banner" />
      <div class="w">
        <Breadcrumb :crumblist="crumbdata" class="hide" />
        <div class="strip">
          <div class="car">
            旅游饭店
          </div>
        </div>
        <div class="stop-box">
          <el-row>
            <ul class="stop-list">
              <li class="stop-item" v-for="item in result" :key="item.id">
                <el-col :md="6">
                  <router-link
                    :to="{
                      path: '/hotelDetail',
                      query: { id: item.id },
                    }"
                  >
                    <div class="whole">
                      <div class="is">
                        <img
                          v-if="item.theLocal"
                          :src="`/jeecg-boot/${item.theLocal}`"
                          class="imgleft"
                        />
                        <img
                          v-else
                          src="@/assets/image/main/zanwutp.png"
                          alt=""
                          class="imgleft"
                        />
                      </div>
                      <div class="title">
                        <span>{{ item.theName }} </span>
                      </div>
                      <span class="xia"> >> </span>
                      <div class="jq">
                        <span class="jq-text">{{ item.theType }}</span>
                      </div>
                      <div class="xq">
                        <p>咨询电话：{{ item.theTel }}</p>
                        <p>标准套房：{{ item.thiSuite }}元</p>
                        <p>景区地址：{{ item.theAdd }}</p>
                      </div>
                    </div>
                  </router-link>
                </el-col>
              </li>
            </ul>
          </el-row>
        </div>
        <Pagi
          :total="pagi.total"
          :current="pagi.current"
          :sizes="pagi.size"
          @pagicurrent="togglePagi"
        />
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Breadcrumb from "@/components/main/Breadcrumb";
import Header from "@/components/main/Header";
import Headers from "@/components/wap/Headers";
import BannerImage from "@/components/main/BannerImage";
import Footer from "@/components/main/Footer";
import Pagi from "@/components/Pagi";
import { getAction } from "@/api/manage";
export default {
  components: {
    Header,
    Footer,
    Pagi,
    Breadcrumb,
    BannerImage,
    Headers,
  },
  created() {
    this.loadData(1);
  },
  data() {
    return {
      crumbdata: [
        { name: "旅游企业", url: "" },
        { name: "旅游饭店", url: "/hotel" },
      ],
      result: [],
      pagi: {
        total: 0,
        current: 1,
        size: 10,
      },
    };
  },
  methods: {
    loadData(value) {
      getAction("/hotel/tourHotel/list", {
        pageNo: value,
      }).then((res) => {
        if (res.success) {
          this.result = res.result.records;
          this.pagi.total = res.result.total;
          this.pagi.current = res.result.current;
          this.pagi.size = res.result.size;
        }
      });
    },
    togglePagi(value) {
      this.loadData(value);
    },
  },
};
</script>
<style lang="scss" scoped>
.hotel {
  .pagi-box {
    text-align: center;
    padding: 30px 0 30px;
  }
  .bars {
    width: 100%;
  }
  background: url(/img/white-bg.9771c1fc.png) no-repeat fixed top left;
  .strip {
    margin-top: 46px;
    margin-bottom: 30px;
    width: 1134px;
    height: 52px;
    background-color: #6EB40021;
    margin-left: 15px;
  }
  .stop-box {
    padding: 20px 0 50px;
  }
  .stop-item {
    padding: 0px 20px 0 0;
  }
  .whole {
    width: 267px;
    height: 362px;
    background-color: #ffffff;
    border: solid 1px #dbdbdb;
    margin-left: 15px;
    margin-top: 20px;
    overflow: hidden;
    .is {
      height: 194px;
      overflow: hidden;
    }
    img {
      width: 257px;
      height: 194px;
      margin-left: 5px;
      margin-top: 7px;
      transition: all 0.6s;
      &:hover {
        transform: scale(1.1);
      }
    }
    .title {
      width: 144px;
      height: 18px;
      font-family: MicrosoftYaHei;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 29px;
      letter-spacing: 0px;
      color: #333333;
      margin-left: 17px;
    }
    .xia {
      float: right;
      margin-right: 18px;
      margin-top: -11px;
    }
    .jq {
      margin-left: 17px;
      margin-top: 9px;
      margin-bottom: 18px;
      .jq-text {
        width: 67px;
        height: 14px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 29px;
        letter-spacing: 0px;
        color: #6EB400;
      }
    }
    .xq {
      margin-left: 17px;
      margin-bottom: 18px;
      p {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 24px;
        letter-spacing: 0px;
        color: #666666;
      }
    }
  }
  .whole:hover {
    transition-delay: 0.2s;
    box-shadow: 0px 2px 5px 0px rgba(42, 74, 128, 0.13);
  }
}
</style>
